<template>
  <v-card>
    <v-row no-gutters>
      <v-col class="scroller" :style="scrollerStyle">
        <v-card class="card">
          <v-card-text>
            <h2>
              Overblik
            </h2>
            <TcoCalculationSummaryTable :questions="questions" :showTags="true" :hoveredTag="hoveredTag" />
          </v-card-text>
        </v-card>
        <v-card class="card">
          <v-card-text>
            <h2>Evalueringsteknisk pris (beregning)</h2>
            <div v-for="i in ['', 'NewOption']" :key="i">
              <v-row v-if="getTable(cat1Hours, i).rows.length > 0">
                <v-col>
                  <TcoCalculation
                    :table="getTable(cat1Hours, i)"
                    :headers="getHeaders(cat1Hours, i)"
                    :hoveredTag="hoveredTag"
                    :tags="[]"
                  />
                </v-col>
              </v-row>
              <v-row v-if="getTable(cat2Hours, i).rows.length > 0">
                <v-col>
                  <TcoCalculation
                    :table="getTable(cat2Hours, i)"
                    :headers="getHeaders(cat2Hours, i)"
                    :hoveredTag="hoveredTag"
                    :tags="[]"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <TcoCalculation
                  :table="getTable(totalSection, '')"
                  :headers="getHeaders(totalSection, '')"
                  :hoveredTag="hoveredTag"
                  :tags="[]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card color="#fff9ef" class="fill-height" flat>
          <v-card-text ref="description">
            <TcoCalculationDescription @updateHoveredTag="updateHoveredTag" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue';
import { DeepQuestion, TcoCalculationTable } from '@dims/components';
import TcoCalculationDescription from './TcoCalculationDescription.vue';
import TcoCalculationSummaryTable from './TcoCalculationSummaryTable.vue';
import TcoCalculation from './TcoCalculation.vue';

const { questions, tables } = defineProps<{
  questions: DeepQuestion[],
  tables: Record<string, TcoCalculationTable> }>();
const scrollerStyle = ref({ maxHeight: undefined as string | undefined });
const hoveredTag = ref<string>();

const cat1Hours = 'Cat1Hours';
const cat2Hours = 'Cat2Hours';
const totalSection = 'Total';

function updateHoveredTag(tag: string) {
  hoveredTag.value = tag;
}

const description = ref<Element>();

onMounted(() => {
  // Set height of left column to height of description
  // We wait for next tick because mounted() does not guarantee that the DOM has finished rendering
  void nextTick(() => {
    if (description.value) {
      const height = description.value.clientHeight;
      scrollerStyle.value.maxHeight = `${height}px`;
    }
  });
});

const headers: Record<string, {value: string, tag: string}[]> = {
  Cat1Hours:
    [
      {
        value: 'Profil',
        tag: '',
      },
      {
        value: 'Konsulent',
        tag: '',
      },
      {
        value: 'Antal timer',
        tag: 'A',
      },
      {
        value: 'Pris',
        tag: 'B',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
  Cat2Hours:
    [
      {
        value: 'Konsulent',
        tag: '',
      },
      {
        value: 'Antal timer',
        tag: 'C',
      },
      {
        value: 'Pris',
        tag: 'D',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
  Total:
    [
      {
        value: 'Total',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: '',
        tag: '',
      },
      {
        value: 'Evalueringsteknisk pris',
        tag: '',
      },
    ],
};

function getTable(section: string, optionId: string) {
  const tableName = optionId.length > 0 ? `${section}${optionId}` : section;
  const table = tables[tableName];
  if (!table) {
    return { rows: [] };
  }
  return table;
}

function getHeaders(section: string, optionId: string) {
  const header = headers[section];
  if (!header) {
    return [];
  }
  const optionHeader = [...header];
  if (optionId.length > 0 && optionHeader[0]) {
    const headerText = 'Ny opgave';
    optionHeader[0] = { value: `Optioner: ${headerText}`, tag: optionHeader[0].tag };
  }
  return optionHeader;
}

</script>

<style scoped>
.card {
  margin: 2em;
  background: rgb(var(--v-theme-canvas-base)) !important;
}

h2 {
  color: rgb(var(--v-theme-primary));
  margin-bottom: 1em;
  margin-top: 0;
  text-transform: uppercase;
}

.scroller {
  overflow-y: scroll !important;
}
</style>
